import React,{useState} from 'react'
import './index.css'
// import Header from '../../Components/Header'
// import geaMotto from '../../Images/geaMotto.png'
import {send} from '@emailjs/browser';
import {FaWhatsappSquare, FaFacebookMessenger} from 'react-icons/fa'
import {MdEmail} from 'react-icons/md'


const Contact = () => {

  const [senderName, setSenderName] = useState('')
  const [senderEmail, setSenderEmail] = useState('')
  const [senderSubject, setSenderSubject] = useState('')
  const [message, setMessage] = useState('')
  const [sent, setSent] = useState(false)


  const handleName = (e) =>{
    setSenderName(e.target.value);
  }

  const handleEmail=(e) =>{
    setSenderEmail(e.target.value);
  }


  const handleSubject = (e) => {
    setSenderSubject(e.target.value)
  }

  const handleMessage= (e) => {
    setMessage(e.target.value);
  }

  const sendMail = (e) =>{
    e.preventDefault();
    send(
      'service_c6k8doo', 'template_v49bjtv', {senderName, senderEmail, senderSubject, message}, '5qWmd08IMnnY7cvBe', setSent(true)
    ).then((response) =>{
      console.log('your Message sent successfully',response.status, response.text)
    })

    setSenderName('');
    setSenderEmail('');
    setSenderSubject('')
    setMessage('');
  }



  return (
    <>
    {/* for the top page we are importing the header components and passing in the props */}
    {/* <Header title="Get In Touch" immage={geaMotto}>
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Rem suscipit quasi vel rerum doloremque laudantium minima adipisci velit officia quaerat!
    </Header> */}

    <div className="contactHeader">
      <h1>Get in Touch</h1>
      <p>Our team is available to respond to your enquiries</p>
    </div>

    <div className="mapContainer ">

      <div className="contact-form">
            <form onSubmit={sendMail}>
              <ul>
                <li className="half-row">
                  <input type="text" name='senderName' placeholder='Name' value={senderName} onChange={handleName} required/>
                </li>
                <li className="half-row">
                  <input type="email" name='senderEmail' value={senderEmail} onChange={handleEmail} placeholder='Email' required/>
                </li>
                <li >
                  <input type="text" name='senderSubject' value={senderSubject} onChange={handleSubject} placeholder='Subject'  required/>
                </li>
                <li>
                  <textarea name="message" value={message} onChange={handleMessage} placeholder='Message' required ></textarea>
                </li>

                <li>
                  <input type="submit"  className='flat-button' value='SEND'/>
                </li>
              </ul>

              <div className={sent ? "msg msgAppear":"msg"}>
                Message sent successfully
              </div>

            </form>

          <div className="map-wrap">
          <iframe className='googleMap' title="google Map" frameBorder="0" scrolling="no" src="https://maps.google.com/maps?;hl=en&amp;q=%2097%20East%20Barnet%20Road%20New%20barnet%20Hertfordshire+(Grace%20empowerment%20Assembly)&amp;t=&amp;z=17&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.gps.ie/wearable-gps/">wearable trackers</a></iframe>
        </div>
      </div>
    </div>


    <section className="contact">
      <div className="container contact__container">
        <div className="contact__wrapper">
          <a href="mailto:info@graceempowermentassembly.com" target="_blank" rel="noopener noreferrer" className='email'><MdEmail /></a>
          <a href="http://m.me/graceempowementassembly" target="_blank" rel="noopener noreferrer" className='facebook'><FaFacebookMessenger /></a>
          <a href="http://wa.me/00447494052360" target="_blank" rel="noopener noreferrer" className='whatsapp'><FaWhatsappSquare /></a>
        </div>
      </div>
    </section>
    </>
  )
}

export default Contact
