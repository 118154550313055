import React, {useState} from 'react'
import {Link, NavLink} from 'react-router-dom'
import Logo from '../../Images/logo1.png'
import {links} from '../../data'
import {GoThreeBars} from 'react-icons/go'
// import {GrClose} from 'react-icons/gr'
import {IoIosCloseCircleOutline} from 'react-icons/io'
import './index.css'

const Navbar = () => {
  const [isNavShowing, setIsNavShowing] = useState(false)

  return (
    <nav>
      {/* the container would be in almost all the app as container whilst nav__container is specifically for navbar */}
          <div className="container nav__container">
            <Link to='/' className="logo" onClick={() => setIsNavShowing(false)}>
              <img src={Logo} alt="Nav logo" />
            </Link>

            {/* i Will use useState hook to toggle the hamburger menu for it to show and also hide */}
           {/* so what im doing is that i'm checking if isNavShowing state is true, if it is , i will give it the class show nav else hide nav. but it will start by hiding itself because the state is set to false*/}

            <ul className={`nav__links ${isNavShowing ? 'show__nav' : 'hide__nav'}`}>
              {
                // we are mapping over links coming from data.js so that I can iterate through them an generate them dynamically.
                // I'm using isActive to identify the active nav link and set the class active-nav to it
                // in Navlink I'm passing the same props as the one on button bc i wan to toggle the links to go on and off when on click
                links.map(({name, path} , index) =>{
                  return (
                    <li key={index}>
                     <NavLink to={path} className={({isActive})=> isActive ? 'active-nav' : ''} onClick={() => setIsNavShowing(previousState => !previousState)}>{name}</NavLink>
                    </li>
                  )
                })
              }
            </ul>
            {/* THIS BUTTON WILL ONLY SHOW ON TABLETS AND MOBILE DEVICES */}
            {/* to toggle the hamburger menu then we have to add an event listener so that we can toggle from show or hide */}
            {/* I created a function previousState then I'm returning the opposite of that function which isd the opposite of ehatever our useState is e.g if it's false it would return true and vice versa */}

            <button className="nav__toggle-btn" onClick={() => setIsNavShowing(previousState => !previousState)}>
              {
                isNavShowing ? <IoIosCloseCircleOutline /> : <GoThreeBars />
              }
            </button>

          </div>
    </nav>
  )
}

export default Navbar
