import React, {useState }from 'react'
import './index.css'
import SectionHead from '../SectionHead'
import Card from '../../UI/Card'
import {ImQuotesLeft} from 'react-icons/im'
import {IoIosArrowDropleftCircle,IoIosArrowDroprightCircle} from 'react-icons/io'
import {Teams }from "../../data"


const AdvertSlides = () => {

  const [index, setIndex] = useState(0)

  // here we are going to get the the first object in our Teams array from data.js and then destructure it
   const {name, quote, job, avatar} = Teams[index];

  //  I'm creating this function so that I can use it in the button to navigate to previous team member slide
  const prevTeamMemberHandler = () =>{
     setIndex (prev => prev - 1)
// we want to check if the index is < or = 0 then we set the state to the length of the array -1 which would be the last slide
     if(index <= 0){
      setIndex (Teams.length - 1)
    }
  }

  //  I'm creating this function so that I can use it in the button to to the next team member slide
  // iniatially the usestate is = 0 so when we click next, we want to increase it
  // we have to get the previous state, and return that previous state + 1. That is the logic we want to us
  const nextTeamMemberHandler = () =>{
    setIndex (prev => prev + 1)
//we add this conditional so that the app would just loop through the content of our array.
    if(index >= Teams.length - 1){
      setIndex (0)
    }
  }



  return (
    <section className="teams">
      <div className="container teams__container">
        <SectionHead  icon={<ImQuotesLeft />} title="The Team" className="teams__head"/>
        <Card className="team">
          <div className="team__avatar">
              <img src={avatar} alt={name} />
          </div>
          <h3>{name}</h3>
          <small className="team__title">{job}</small>
          <p className='team__quote'>{`"${quote}"`}</p>
        </Card>
        <div className="teams__btn-container">
          {/* onclick of the button, we have to show the previous team member on the slide so that is what we have to code now */}
          <button className="teams__btn" onClick={prevTeamMemberHandler}><IoIosArrowDropleftCircle/></button>
          <button className="teams__btn" onClick={nextTeamMemberHandler}><IoIosArrowDroprightCircle/></button>
        </div>
      </div>
    </section>
  )
}

export default AdvertSlides
