import React from 'react'
import './index.css'
import { Link } from 'react-router-dom'
import Logo from '../../Images/logo1.png'
import {FaTiktok, FaInstagramSquare} from 'react-icons/fa'
import {BsYoutube, BsFacebook} from 'react-icons/bs'


const Footer = () => {
  return (
    <footer>
      <div className="container footer__container">
        <article>
          <Link to="/" className='logo'>
            <img src={Logo} alt="" />
          </Link>
          <p>
            As a church we want to bring awareness about God’s existence to the world. If you wish to become a part of our church or a volunteer to help those in need, our community awaits you with open hearts.
          </p>
          <div className="footer__socials">
            <a href="https://www.youtube.com/channel/UCDd4NvA9RuKg8k3DLsg7uwA" target='_blank' rel="noreferrer noopener"> <BsYoutube /></a>
            <a href="https://www.facebook.com/profile.php?id=100083926372902"  target='_blank' rel="noreferrer noopener"> <BsFacebook /></a>
            <a href="https://www.tiktok.com/@grace_empowerment"  target='_blank' rel="noreferrer noopener"> <FaTiktok /></a>
            <a href="https://www.instagram.com/grace_empowerment_assembly/"  target='_blank' rel="noreferrer noopener"><FaInstagramSquare /></a>
          </div>
        </article>

        <article>
          <h4>Quick Links</h4>
          <Link to="/about">ABout</Link>
          <Link to="/Donate">Donate</Link>
          <Link to="/Team">Teams</Link>
          <Link to="/gallery">Gallery</Link>
          <Link to="/contact">Contact</Link>
        </article>
        <article>
          <h4>Insights</h4>
          <Link to="/s">Blogs</Link>
          <Link to="/s">Case Studies</Link>
          <Link to="/s">Events</Link>
          <Link to="/s">FAQs</Link>
          <Link to="/s">Communities</Link>
        </article>
        <article>
          <h4>Get In Touch</h4>
          <Link to="/contact">Contact Us</Link>
          <Link to="/team">Support</Link>

        </article>
      </div>
      <div className="footer__copyright">
        <small>2023 Grace Empowerment Assembly &copy; All Rights Reserved. Charity number: 1173929</small>
      </div>

       <div className="copyright">
           <p class="copyright"><i>Designed by</i>
              <i>
                <a rel="noopener noreferrer" href="https://www.linkedin.com/in/ikenna-agulobi/" target="_blank"> Sinon Tech</a>
              </i>
            </p>
    </div>

    </footer>
  )
}

export default Footer
