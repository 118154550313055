import { BrowserRouter, Routes, Route } from 'react-router-dom'

import Home from './Pages/Home/Home'
import About from './Pages/About/About'
import Contact from './Pages/Contact/Contact'
// import Gallery from './Pages/Gallery/Gallery'
import Donate from './Pages/PlansDonate/PlansDonate'
import Team from './Pages/Team/Team'
import NotFound from './Pages/NotFound/NotFound'
import Navbar from './Components/Navbar'
import NavbarTop from './Components/NavbarTop'
import Footer from './Components/Footer'
import Services from './Components/Services'
import Gallery2 from './Pages/GalleryTwo/'



const App = () => {
  return (
    <>

    <BrowserRouter>
      <NavbarTop />
      <Navbar />
        <Routes>
          <Route index element={ <Home />} />
          <Route path='service' element={ <Services />} />
          <Route path='about' element={ <About />} />
          <Route path='contact' element={ <Contact />} />
          {/* <Route path='gallery' element={ <Gallery />} /> */}
          <Route path='gallery2' element={ <Gallery2 />} />
          <Route path='team' element={ <Team />} />
          <Route path='donate' element={ <Donate />} />
          <Route path='contact' element={ <Contact />} />
          <Route path='*' element={ <NotFound />} />  {/*this is set to all other paths which has no route and it would say not found what you are looking for sand redirect to useful page or put a phone number.  */}
        </Routes>

      <Footer />

    </BrowserRouter>
    </>
  )
}

export default App
