import React from 'react'
import Card from '../../UI/Card'

const Minister = ({avatar, name, quote, job}) => {
  return (
    <Card className="minister">
      <div className="minister__image">
        <img src={avatar} alt={name} />
      </div>
      <h3>{name}</h3>
      <h5>{job}</h5>
      <p>{quote}</p>
    </Card>
  )
}

export default Minister
