import React from 'react'
import './index.css'
import {FaBible} from 'react-icons/fa'
import SectionHead from '../SectionHead'
import {programs} from '../../data'
import Card from '../../UI/Card'
// import Header from '../../Components/Header'
// import geaMotto from '../../Images/geaMotto.png'
// import { Link } from 'react-router-dom'
// import {BiRightArrow} from 'react-icons/bi'

// I would pass the icon prop to the sectionhead ie the icon, the title and the className if necessary.
// I would also add time and button as prop later

// we would map on our array of programs which we are getting from data.js, so that we can dispalay the content we want
const Services = () => {
  return (
    <>
    {/* <Header title="Gallery" image={geaMotto }>

    </Header> */}
    <section className="programs">
      <div className="container programs__container">
        {/* this is only for the ection head */}
        <SectionHead  icon={ <FaBible/>} title="Join our weekly Services"/>

        <div className="programs__wrapper">
            {
              // to destructure the array programs, we have to pass into the map, the keys of the objects
              programs.map(({id,icon,image,title,info,time, path}) => {
              // we will import the card component here and it will wrap around our elements
                return (
                  // we should have a unique key on our card bc it is an array/list
                  <Card className="programs__program"  key={id}>
                    <img src={image} alt="prayer meeting" />
                    {/* <span>{icon}</span> */}
                    <h3>{title}</h3>
                    <small>{info}</small>
                    <small>{time}</small>
                    {/* <Link to={path} className="btn sm">Join Service <BiRightArrow /></Link> */}
                     <button className="btn-service" type='submit'><a href="https://us02web.zoom.us/j/9031315880?pwd=S2RRL284bm8rUmNpUE8valZ3S0RuUT09" target='_blank' rel="noreferrer">Join</a></button>
                  </Card>
                )
              })
            }
        </div>
        {/* <button className="btn sm">Join Service <BiRightArrow /></button> */}
      </div>
    </section>
    </>


  )
}

export default Services
