import React from 'react'
import './index.css';
import {IoIosArrowDropleftCircle} from 'react-icons/io'
import Header from '../../Components/Header'
import geaMotto from '../../Images/geaMotto.png'
import Img1 from '../../img-Gallery/gallery1.png';
import Img2 from '../../img-Gallery/gallery2.png';
import Img3 from '../../img-Gallery/gallery3.png';
import Img4 from '../../img-Gallery/gallery4.png';
import Img5 from '../../img-Gallery/gallery5.png';
import Img6 from '../../img-Gallery/gallery6.png';
import { useState } from 'react'; //I can import usestate here or add it to the import react above like this {useState}.


const Gallery2 = () => {

  let data = [
    {
      id:1,
      imgSrc: Img1
    },
    {
      id:2,
      imgSrc: Img2
    },
    {
      id:3,
      imgSrc: Img3
    },
    {
      id:4,
      imgSrc: Img4
    },
    {
      id:5,
      imgSrc: Img5
    },
    {
      id:6,
      imgSrc: Img6
    }
  ]

//this will apply the usetate on the img
  const [model, setModel] = useState(false);
  const[tempimgSrc, setTempImgSrc] = useState('')

   const getImg = (imgSrc) => {
    setTempImgSrc(imgSrc);
    setModel(true);
  }

  return (
    <>
    <Header title="Gallery" image={geaMotto }>

      </Header>


    <div className={model? "model open" : "model "}>
      <img src={tempimgSrc} alt='imgModel' />
      <IoIosArrowDropleftCircle onClick={() => setModel(false)}/>

    </div>

      <div className="gallery">
        {data.map((item, index)=>{
          return(
            <div className="pics" key={index} onClick={() => getImg(item.imgSrc)}>
              <img src={item.imgSrc} alt='imgModel'/> {/*style={{width: '100%'}}*/}
            </div>
          )
        })}
      </div>
    </>
  )
}

export default Gallery2
