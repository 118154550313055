import React from 'react'
import './index.css'
import Header from '../../Components/Header'
import geaMotto from '../../Images/geaMotto.png'
import {Teams} from '../../data'
import Minister from '../../Components/Minister'



const Team = () => {
  return (
    <>
     <Header title="Meet the Team" imagge={geaMotto}>
      Grace Empowerment Assembly team is always available to welcome and assist you.
    </Header>
    <section className="ministers">
      <div className="container ministers__container">
        {
          Teams.map(({id, avatar, name, quote, job}) => {
        return <Minister key={id} avatar={avatar} name={name} job={job} quote={quote} >
            </Minister>
          })
        }
      </div>
    </section>

    </>
  )
}

export default Team
