import React from 'react'
import AdvertSlides from '../../Components/AdvertSlides'
import FAQs from '../../Components/FAQs'
import MainHeader from '../../Components/MainHeader/Index'
import Services from '../../Components/Services/index'
import Values from '../../Components/Values'
import './index.css'

const Home = () => {
  return (
    <>
     <MainHeader />
     <Services />
     <Values />
     <FAQs />
     <AdvertSlides />
    </>
  )
}

export default Home
