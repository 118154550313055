import React from 'react'
import './index.css'
import SectionHead from '../SectionHead'
import {FaQuestion} from 'react-icons/fa'
import {faqs} from '../../data'
import FrequentQuestion from '../FrequentQuestion'

const FAQs = () => {
  return (
   <section className="faqs">
    <div className="container faqs__container">
      <SectionHead icon={<FaQuestion/>} title="FAQs" />
      <div className="faqs__wrapper">
        {
          faqs.map(({id, question, answer}) => {
            return <FrequentQuestion key={id} question={question} answer={answer}/>
          })
        }
      </div>
    </div>
   </section>
  )
}

export default FAQs
