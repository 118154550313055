import React from 'react'
import './index.css'
import {donate }from '../../data'
// import Header from '../../Components/Header'
// import geaMotto from '../../Images/geaMotto.png'
import Card from '../../UI/Card'

const PlansDonate = () => {
  return (
    <>
    {/* <Header title="Donations" image={geaMotto}>
      Each one of you should give what you have decided in your heart to give, not reluctantly or under compulsion, for God loves a cheerful Giver.
    </Header> */}

    <div className="donationHeader">
      <div className="donationHeaderContent">
        <h1>Donations</h1>
        <small>2Chorinthians 9:7</small>
        <p>" Each one of you should give what you have decided in your heart to give, not reluctantly or under compulsion, for God loves a cheerful giver "</p>
      </div>
    </div>

    <section className="donations">
      <div className="container donation__container">
        {
          donate.map(({id,name,desc,account,code}) =>{
            return <Card key={id} className='donateCard'>
              <h3>{name}</h3>
              <small>{desc}</small>
              <small><strong>{account}</strong></small>
             <small><strong>{code}</strong></small>
            </Card>
          })
        }
      </div>

    </section>
    </>
  )
}

export default PlansDonate
