import React from 'react'

const Card = ({className, children}) => {
  return (
    <article className={`card ${className}`}>
        {children} {/* this will work like a react fragment */}
    </article>
  )
}

export default Card
