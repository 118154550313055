import React from 'react'
import Deliverance from '../../Images/deliverance2.png'
import SectionHead from '../SectionHead' //this is a reusable component
import { BsGiftFill} from 'react-icons/bs'
import {values} from '../../data' // coming from data.js
 import Card from '../../UI/Card' //this is a reusable component
 import './index.css'

const Values = () => {
  return (
    <section className='values'>
      <div className="container values__container">

        <div className="values__left">
          <div className="values__image">
            <img src={Deliverance} alt="values" />
          </div>
        </div> {/* left side value container div  end*/}

        <div className="values__right">
          <SectionHead icon={<BsGiftFill />} title="Values" />
          <p>
            We are a friendly community with the aim of bringing the awareness of God's existence to the world and to share the love of Jesus with all nations. <br></br> We are defined by the below values
          </p>
          <div className="values__wrapper">
            {
              values.map(({id,image, title, desc,scripture})=> {
                return <Card key={id} className="values__value">
                  {/* <span>{icon}</span> */}
                  {/* <img src={image} alt="values" /> */}
                  <h3>{title}</h3>
                  <p>{desc}</p>
                  <small><strong>{scripture}</strong></small>
                </Card>
              })
            }
          </div>
        </div>  {/*right side value container div end */}
      </div>

    </section>
  )
}

export default Values
