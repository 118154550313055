// we would use the useSatte hook to toggle the qustions and answers. so that when you click , it show the answers.


import React, {useState} from 'react'
import {AiOutlinePlus} from 'react-icons/ai'
import {AiOutlineMinus} from 'react-icons/ai'
import './index.css'

// here we are going to destructure our FAQ
const FrequentQuestion = ({question, answer}) => {

  // within my component i'm going to return a ueststate hook which returns 2 elemnts
 // the firs element is state value , the 2nd element is the state update function.

 const [isAnswerShowing, setIsAnswerShowing] = useState(false)

  return (
    // what I'm doing here is that onclick, I'm returning the prvious state of my element. if it was false it becomes true and vice versa
    // this would enenable us be able to toggle our frquently asked question cards
    <article className='faq' onClick={() => setIsAnswerShowing(prev => !prev) }>
      <div>
        <h4>{question}</h4>
        <button className="faq__icon">
         {
          isAnswerShowing? <AiOutlineMinus /> : <AiOutlinePlus />
         }
        </button>
      </div>
      {isAnswerShowing && <p>{answer}</p>}
    </article>
  )
}

export default FrequentQuestion
