import React from 'react'
import './index.css'
import Gallery2 from '../../Components/Gallery2/index'


const GalleryTwo = () => {
  return (
    <div>
      <Gallery2/>
    </div>
  )
}

export default GalleryTwo
