import React from 'react'
import './index.css'

//title, image, children are the props that we would be importing from data.js so that we can reuse this component on each page
// the bellow code is our header component which is reusable
const Header = ({title, image, children }) => {
  return (
    <header className='header'>
      <div className="header__container">
        <div className="header__container-bg">
          {/* <img src={image} alt="header background" /> */}
        </div>
        <div className="header__content">
          <h2>{title}</h2>
          <p>{children}</p> {/*this are going to be the children we would be passing to the header component*/}
        </div>
      </div>
    </header>
  )
}

export default Header
