// import React, {useState, useEffect}from 'react'
import {Link} from 'react-router-dom'
// import LandingImage from '../../Images/geaMotto.png'
// import {ImQuotesLeft, ImQuotesRight} from 'react-icons/im'
import PastorImage from '../../Images/abbey_&_wife.png'
// import AnimatedLetters from '../AnimatedLetters'

import './index.css'

const MainHeader = () => {

  return (

    <>

     <header className='main__header'>
      <div className="container main__header-container">
        <div className="main__header-left">

          {/* <h4><ImQuotesLeft className='quotes'/>Jesus Christ is the way, the truth and the life <ImQuotesRight className='quotes'/></h4> */}
          <span className='text1'>welcome to </span> <br></br>
          <span className='text2'> Grace Empowerment Assembly</span>

          <h2 style={{color: "#fff"}}> Join us live every Sunday at 1:30PM <br></br>
          </h2>
          <button className="btn lg" type='submit'><a href="https://www.youtube.com/channel/UCDd4NvA9RuKg8k3DLsg7uwA" target='_blank' rel="noreferrer">Join Live</a></button>
          <Link to="/contact" className='btn lg'>Get in Touch</Link>
        </div>

        {/* <div className="main__header-right">
          <div className="main__header-circle"></div>
          <div className="main__header-image">
            <img src={LandingImage } alt="Main Header " />
          </div>
        </div> */}
      </div>
    </header>

     <section className="about__story">
        <div className="container about__story-container">
          <div className="about__section-image">
            <img src={PastorImage} alt="about story " />
          </div>
          <div className="about__section-content">
            <h1>About Us</h1>
            <p>
             We have a strong sense of community. People and children of all ages in our church are encouraged to learn about their own faith and the role of the church in our community and worldwide...
            </p>
            <Link to="/about" className='readMe'>Read More</Link>
          </div>
        </div>
       </section>


    </>

  )
}

export default MainHeader
