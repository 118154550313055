
import {BsPersonCircle} from 'react-icons/bs'
import {FaHandshake} from 'react-icons/fa'


export const links = [
  {
    name: 'Home',
    path: '/'
  },
   {
    name: 'About',
    path: '/about'
  },
  {
    name: 'Service',
    path: '/service',
  },
  // {
  //   name: 'Gallery',
  //   path: '/gallery'
  // },
  {
    name: 'Gallery',
    path: '/gallery2'
  },
  {
    name: 'Donate',
    path: '/donate'
  },
  {
    name: 'Team',
    path: '/team'
  },
  {
    name: 'Contact',
    path: '/contact'
  }
]


export const programs = [
  {
    id:1,
    icon:<BsPersonCircle />,
    image:require("./Images/prayer-meeting.png"),
    title: 'Wednesday prayer',
    info: 'Join us on zoom every Wednesday for our prayer Meeting. Do not forget to bring your prayer requests.',
    time: " Time: 9pm - 10pm ",
    path: "/about"
  },
  {
    id:2,
    icon:<FaHandshake />,
    image:require("./Images/bible-study.png"),
    title: 'Friday Bible Study',
    info: 'Join us on zoom every Friday for an interactive bible study, where we dig deep into the word of God',
    time: " Time: 9pm - 10pm ",
    path: "/about"
  },
  {
    id:3,
    icon:<BsPersonCircle />,
    title: 'Women\'s prayer',
    image:require("./Images/women-prayer.png"),
    info: 'Join us on zoom every second Thursday of the Month for Women\'s prayer. Do not forget to bring your prayer requests ',
    time: " Time: 8pm - 9pm ",
    path: "/about",

  },
  {
    id:4,
    icon:<FaHandshake />,
    title: 'Night vigil ',
    image:require("./Images/night-vigile.png"),
    info: 'Join us every last Friday of the month for night vigil. A moment of intense prayers and worship ',
    time: " Time: 10pm - 12.30am ",
    path: "/about"
  }
]

export const values = [
  {
    id:1,
    icon:<FaHandshake />,
    image:require("./Images/focusJesus.png"),
    title: '1.  Focus on Jesus Christ  ',
    desc: 'We focus on Jesus as He is the way, the truth and the life. No man gets to the father except through Him',
    scripture: 'John 14:6'
  },
  {
    id:2,
    icon:<FaHandshake />,
    image:require("./Images/truth1.png"),
    title: '2. We stand for truth ',
    desc: 'As commanded in the book of Ephesians, we  stand firm with the belt of truth around our waist and the armor of godliness on our chest',
    scripture: 'Ephesians 6:14'
  },
  {
    id:3,
    icon:<FaHandshake />,
    image:require("./Images/serve1.png"),
    title: '3. Love and serve people ',
    desc: 'As a church we strive to love and serve others as we were commanded by our Lord Jesus ',
    scripture: 'John 13:34'
  },
  {
    id:4,
    icon:<FaHandshake />,
    image:require("./Images/prayer1.png"),
    title: '4. Prayer',
    desc: 'We stand by prayers as it is our primary way of communicating with our Lord. Whatever we ask through prayers and believe, we shall receive',
    scripture: 'Mark 11:24'
  }
]

export const faqs = [
  {
    id:1,
    icon:<BsPersonCircle />,
    question: 'How do I learn to pray ? ',
    answer: 'Prayer is the means all believers communicates with God. If you have given your life to Christ and have received the Holy spirit, you should ask the holy spirit to help you in your prayers by sharing your thoughts and burdens with Him. Do not worry if your prayer is long or short, the Holy spirit will grant you wisdom on what to pray. The next step is to join a prayer group where your prayer life will be sharpened through the help of the Holy spirit. Checkout our wednesday pray meeting if you will be interested to join us.'
  },
  {
    id:2,
    icon:<BsPersonCircle />,
    question: 'what to do when I don\'t understand the bible ?',
    answer: 'Ask the Holy spirit to give you understanding. Speak to a mature Christian or to your leaders at church for mentorship. Join a bible study group. '
  },
  {
    id:3,
    icon:<BsPersonCircle />,
    question: 'How often should I pray ? ',
    answer: 'In Ephesian 6:18, the Word of God commands believers to pray at all times with all kinds of prayers and request. We should be very alert in prayers and also pray for the people of the lord'
  },
  {
    id:4,
    icon:<BsPersonCircle />,
    question: 'How do I develop interest in attending church Service ?',
    answer: 'Through prayers, ask God to help you find a local church in your area. Find a way to make friends with people who are committed to attending regular fellowship at church. Having these kind of support system or structure is very useful when you feel unmotivated to attend fellowship.'
  }
]

export const Teams = [
  {
    id:1,
    name: 'Melanie Agulobi',
    job: "Protocol coordinator",
    quote: 'Helps to oversee the successful completion of church service and events ',
    avatar: require("./Images/mel.png")
  },
  {
    id:2,
    name: 'Ikenna Agulobi',
    job: "Technical Department Manager",
    quote: 'Help with Overseeing the Technical and IT department. ',
    avatar: require("./Images/min-ike1.png")
  },
  {
    id:3,
    name: 'Blessing Utu',
    job: "Choir Member",
    quote: 'Active choir member and helps to assist catering department. ',
    avatar: require("./Images/blessing.png")
  },
  {
    id:4,
    name: 'Ubong Utu',
    job: "Technical department Team member",
    quote: 'Helps assist Technical department and active choir member ',
    avatar: require("./Images/ubong.png")
  },
  {
    id:5,
    name: 'Esohe Salau',
    job: " Choir Lead",
    quote: 'Helps assist with Financial management of church funds and lead church choir ',
    avatar: require("./Images/Deacon.png")
  },
  {
    id:6,
    name: 'Emmanuel Salau',
    job: "Head of Youth department",
    quote: 'Helps assist with the youth department ',
    avatar: require("./Images/emmanuel.png")
  }
]

export const donate = [
  {
    id:1,
    name:'For tithes and offerings',
    desc: "Please Transfer to:",
    account: "Acc Number: 03688186",
    code: 'SortCode: 20-29-81',
  },
  {
    id:2,
    name:'For special offerings',
    desc: "Please Transfer to:",
    account: "Acc Number: 03688186",
    code: 'SortCode: 20-29-81',
  },
  {
    id:3,
    name:'For Church support and development',
    desc: "Please Transfer to:",
    account: "Acc Number: 03688186",
    code: 'SortCode: 20-29-81',
  }
]
