import './index.css'
import React from 'react'
import Header from '../../Components/Header'
import geaMotto from '../../Images/family2.png'
import AboutImage from '../../Images/history.png'
import VisionImage from '../../Images/vision.jpeg'
import MissionImage from '../../Images/mission_1.png'

const About = () => {
  return (
    <>
    {/* the children Props are the content between the header tag thats why I didn't use A single header closing tag */}
    {/* here as well I'm not creating reusable components as I wont need them in other pages */}
       <Header   title="About us" image={geaMotto} className='aboutHeader'>

       </Header>

       <section className="about__story">
        <div className="container about__story-container">
          <div className="about__section-image">
            <img src={AboutImage} alt="about story " />
          </div>
          <div className="about__section-content" id="ourStory">
            <h1>Our Story</h1>
            <p>Grace Empowerment Assembly (GEA) was founded in 2022 by Pastor Abbey Salau after pastoring under Redeemed Christian Church of God for over 10 years.<br></br>
            </p>
            <p>
              With deep compassion for lost souls, he has a great burden to see Gods children saved from the power of sin through Jesus Christ.
            </p>
            <p>
            </p>
          </div>
        </div>
       </section>

       <section className="about__vision">
        <div className="container about__vision-container">

          <div className="about__section-content">
            <h1>Our Vision</h1>
            <p>We are a church that believes in Jesus christ and are committed to empowering and loving people according to the truth of the word of God.
            </p>
            <p>
              <strong>1.</strong> Empower and transform lives through the word of God.<br></br>
            </p>
            <p>
               <strong>2.</strong> We are interested in building strong family lives
                on the foundation of the word of God.
            </p>
            <p>
               <strong>3.</strong> Demonstrate  our christian love  to the
              communities and families
            </p>
          </div>
           <div className="about__section-image">
            <img src={VisionImage} alt="about vision " />
          </div>
        </div>
       </section>

       <section className="about__mission">
        <div className="container about__mission-container">
          <div className="about__section-image">
            <img src={MissionImage} alt="Our Mission " />
          </div>
          <div className="about__section-content">
            <h1>Our Mission</h1>
               <br></br>
              <strong>1.</strong> Bring awareness about God’s existence.  <br></br>
              <strong>2.</strong> Empower people to finding their gift in Christ. <br></br>
              <strong>3.</strong> Be a drive for change and transformation<br></br>
              <strong>4.</strong> Raise Kingdom builders through the Word and by prayer<br></br>
              <strong>5.</strong> Creating a family unit where members can thrive<br></br>

              <br></br><br></br>
              Jesus Christ, the same yesterday, and today, and forever (Hebrews 13:8)
          </div>
        </div>
       </section>



    </>
  )
}

export default About
