import React from 'react'

//  we would be expecting icons as props, so we would be destructuring the props bc it's an object
// the reason I created this component is bc I want to reuse it in any page I want
const SectionHead = ({icon, title, className}) => {
  return (
       <div className={`section__head ${className}`}> {/* we would interpolate the className so that we have it incase we need it*/}
          <span>{icon}</span>
          <h2>{title}</h2>
        </div>
  )
}

export default SectionHead

// explaining the sextion head...
// NB: this above section I created It as  component so that I can use it anywhere in my project. so passed in some props I would expecting e.g icon, title and classname. so that is how props works in a nutshell
